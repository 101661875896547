* {
  -webkit-appearance: none
}

/* You can add global styles to this file, and also import other style files */
:root {
  --Azul333: #d3f0f7;
  --Negro-2: #9b9b9b;
  --Negro-1: #4e4e4e;
  --Negro-3: #d1d1d1;
  --Negro: #1f1f1f;
  --Azul111: #23b4d9;
  --Azul11: #006DB3;
  --Azul222: #7BD2E8;
  --Primary: #545f71;
  --errorText: #b1000f;
  --Verde-oscuro: #90BD00;
  --Outline: #9AA8BC;
  --Gris-claro: #FBFBFB;
  --Verde-1: #A7CF28;
  --Amarillo: #ffde6c;
  --Naranja-1: #F07019;
  --clear-gray: #ECEFF4
}

@font-face {
  font-family: "Gotham Rounded";
  src: url("./assets/fonts/Gotham_Rounded_Bold.otf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Gotham Rounded";
  src: url("./assets/fonts/Gotham_Rounded_Medium.otf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Gotham Rounded";
  src: url("./assets/fonts/Gotham_Rounded_Light.otf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Nunito Sans";
  src: url("./assets/fonts/NunitoSans_Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Nunito Sans";
  src: url("./assets/fonts/NunitoSans_SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Nunito Sans";
  src: url("./assets/fonts/NunitoSans_Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter-VariableFont_Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter-VariableFont_Regular.ttf") format("truetype");
  font-weight: 400;
}

body {
  background-color: #FAFAFA;
}

/* html,
body {
  margin: 0;
  padding: 0;
  background-color: #FAFAFA;
} */
/* button:disabled {
  background-color: black;
}
.text-danger {
  color: var(--errorText);
  font-size: 12px;
}
/*
.title {
  color: #1f1f1f;
  width: 448px;
  font-size: 32px;
  font-family: "Gotham Rounded", sans-serif;
  font-weight: 700;
  word-wrap: break-word;
  margin-bottom: 24px;
}/*

/* Icons */
.cvu-icon {
  width: 24px;
  height: 24px;
  display: block;
  background-size: cover;
  background-color: var(--Negro);
  -webkit-mask: no-repeat center;
  mask: no-repeat center;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 100%;
}

.cvu-icon-user {
  mask-image: url(/assets/icons/user.svg);
  -webkit-mask-image: url(/assets/icons/user.svg);
}

.cvu-icon-menu {
  mask-image: url(/assets/icons/menu.svg);
  -webkit-mask-image: url(/assets/icons/menu.svg);
}

.cvu-icon-close {
  mask-image: url(/assets/icons/close.svg);
  -webkit-mask-image: url(/assets/icons/close.svg);
}

.cvu-icon-exit {
  mask-image: url(/assets/icons/exit.svg);
  -webkit-mask-image: url(/assets/icons/exit.svg);
}

.cvu-icon-left-arrow {
  mask-image: url(/assets/icons/left-arrow.svg);
  -webkit-mask-image: url(/assets/icons/left-arrow.svg);
}

.cvu-icon-plus-circle {
  mask-image: url(/assets/icons/plus-circle.svg);
  -webkit-mask-image: url(/assets/icons/plus-circle.svg);
}

.cvu-icon-menu-dots {
  mask-image: url(/assets/icons/menu-dots.svg);
  -webkit-mask-image: url(/assets/icons/menu-dots.svg);
}

.cvu-icon-share {
  mask-image: url(/assets/icons/share.svg);
  -webkit-mask-image: url(/assets/icons/share.svg);
}

.cvu-icon-edit {
  mask-image: url(/assets/icons/edit.svg);
  -webkit-mask-image: url(/assets/icons/edit.svg);
}

.cvu-icon-success {
  mask-image: url(/assets/icons/success.svg);
  -webkit-mask-image: url(/assets/icons/success.svg);
  background-color: #17BF33;
}

.cvu-icon-failed {
  mask-image: url(/assets/icons/error_icon.svg);
  -webkit-mask-image: url(/assets/icons/error_icon.svg);
  background-color: #F14A58;
}

.cvu-icon-warning {
  mask-image: url(/assets/icons/warning.svg);
  -webkit-mask-image: url(/assets/icons/warning.svg);
  background-color: var(--Naranja-1);
}

.cvu-icon-download-blue {
  mask-image: url(/assets/icons/download.svg);
  -webkit-mask-image: url(/assets/icons/download.svg);
}

.cvu-icon-certification {
  mask-image: url(/assets/icons/certification.svg);
  -webkit-mask-image: url(/assets/icons/certification.svg);
}

.cvu-icon-refund {
  mask-image: url(/assets/icons/Refund.svg);
  -webkit-mask-image: url(/assets/icons/Refund.svg);
}

.cvu-icon-check {
  mask-image: url(\assets\icons\check.svg);
  -webkit-mask-image: url(\assets\icons\check.svg);
}

.cvu-icon-help {
  mask-image: url(/assets/icons/help.svg);
  -webkit-mask-image: url(/assets/icons/help.svg);
}
